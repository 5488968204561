import React, { Component } from 'react'
import { Input, Table, Label, Form, Dropdown, Button, Container, Checkbox } from 'semantic-ui-react'


import FormGeneral from './FormGeneral'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'


class SearchGeneral extends Component {

    render() {
        return (
            <Form id="search-general">
            <FormGeneral/>
            <Container  textAlign='right'><Button>Search</Button></Container>
            </Form>
             );

    }
}

export default SearchGeneral;