import axios from 'axios'
import React, { Component } from 'react'
import { Input, Table, Label, Form, Dropdown, Button, Container } from 'semantic-ui-react'


class SearchResult extends Component {



    constructor(props) {
        super(props)
    }

    renderResultData(data) {
        var output = data.map((element) =>
            <Table.Row>
                <Table.Cell><a href={"/res/" + element["res_num"]} target="_blank">{element["res_num"]}</a></Table.Cell>
                <Table.Cell>{element["res_loc"]}</Table.Cell>
				<Table.Cell>{element["res_date"]}</Table.Cell>
                <Table.Cell>{element["res_title"]}</Table.Cell>
				<Table.Cell>{element["sancNum"]}</Table.Cell>
				<Table.Cell>{element["peaceNum"]}</Table.Cell>
				<Table.Cell>{element["nunNum"]}</Table.Cell>
            </Table.Row>
        )

        return output
    }


    render() {

        var renderedData = this.renderResultData(this.props.data)

        return (
        <div>
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Resolution number</Table.Cell>
						<Table.Cell>Country/ Region</Table.Cell>
						<Table.Cell>Date</Table.Cell>
                        <Table.Cell>Title</Table.Cell>
						<Table.Cell>Sanctions</Table.Cell>
						<Table.Cell>Peace Ops</Table.Cell>
						<Table.Cell>NonUN Ops</Table.Cell>
                    </Table.Row>
                    {renderedData}
                </Table.Body>
            </Table>
        </div>
        )

    }
}

export default SearchResult;