import React, { Component } from 'react'
import { Header,Container, Tab} from 'semantic-ui-react'
import axios from 'axios'

import Settings from '../settings/Settings'





class PageContact extends Component {

   const

   constructor(props) {
        super(props);
           this.state = {
                    data: "",
                }
   }

    fetch() {
        //axios.get(Settings.API_OPENACCESS).then(response => {
        //this.setState({data:response.data})})
    }

    componentDidMount() {
        this.fetch();
    }

    handleChange = (e, data) => this.setState(data)

    render () {
        return (
            <div className="home-content">
                <Container textAlign="left">
                <Header as="h1">Search</Header>

                </Container>
            </div>
        )
    }
}

export default PageContact;