class Settings {

    constructor() {
        this.API_URL = "/api"
        this.API_URL_CONTENT_MAIN = this.API_URL + "/cnt-main"
        this.API_URL_CONTENT_ABOUT = this.API_URL + "/cnt-about"
        this.API_URL_RESOLUTION = this.API_URL + "/resolution"
        this.API_URL_SEARCH = this.API_URL + "/resolution/search"
        this.API_URL_RESOLUTION_TITLES = this.API_URL + "/resolution/titles"
    }
}

export default new Settings();