import React, {Component} from 'react'
import {Container} from 'semantic-ui-react'
import {Route, Switch} from 'react-router-dom'

//---- Import widgets common for all pages
import Header from '../cmp/Header'
import MenuBar from '../cmp/MenuBar'
import Footer from '../cmp/Footer'
//---- Import all pages related to the project
import PageMain from './PageMain'
import PageContact from './PageContact'
import PageAbout from './PageAbout'
import PageResolution from './PageResolution'


//---- Class for routing urls to particular pages.
//---- After the pages are generated, display them.
class PageGenerator extends Component {

    render() {
        return (
          <div>
            <Header/>
            <MenuBar/>

            <Switch>
                <Route path="/" exact component={PageMain}/>
                <Route path="/main" exact component={PageMain}/>
                <Route path="/contact" exact component={PageContact}/>
                <Route path="/about" exact component={PageAbout}/>
                <Route path="/res/:id" exact component={PageResolution}/>
            </Switch>

            <div className="footer-container"/>

            <Container><Footer/></Container>


          </div>
        )
    }
}

export default PageGenerator;