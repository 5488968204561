
import React, { Component } from 'react'
import {Table, Input, Dropdown} from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import axios from 'axios'

import Settings from '../settings/Settings'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import './css/form-bsc.css'

class FormBasic extends Component {


   constructor(props) {
        super(props);
        this.state = {
            resolutionTitleList: [],
        }
   }

    fetch() {
        axios.get(Settings.API_URL_RESOLUTION_TITLES).then(response => {
        console.log(response.data)
        var temp = []
        for(var i = 0; i < response.data.length; i++) {
            temp.push({value: response.data[i], text: response.data[i]})
        }
        this.setState({resolutionTitleList:temp})})
    }

    componentDidMount() {
        this.fetch();
    }

    render() {
        return (
            <Table>
                <Table.Body>
                    <Table.Row>
                      <Table.Cell>Resolution ID</Table.Cell>
                      <Table.Cell><Input className="in-resid" id="res-id" onChange={this.props.callback}/></Table.Cell>
                      <Table.Cell>From date:</Table.Cell>
                      <Table.Cell><SemanticDatepicker className="date" id="date-start" onChange={this.props.callback}/></Table.Cell>
                      <Table.Cell>To date:</Table.Cell>
                      <Table.Cell><SemanticDatepicker className="date" id="date-end" onChange={this.props.callback}/></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                     <Table.Cell>Title:</Table.Cell>
                     <Table.Cell colSpan='4'><Dropdown clearable placeholder="title" search selection options={this.state.resolutionTitleList} className="in-title" id="res-title" onChange={this.props.callback}/></Table.Cell>
                     <Table.Cell></Table.Cell>
                     <Table.Cell></Table.Cell>
                     <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    </Table.Row>

                </Table.Body>
            </Table>

                );

    }
}

export default FormBasic;