import React, { Component } from 'react'
import { Header,Container, Tab, Segment} from 'semantic-ui-react'
import axios from 'axios'

import Settings from '../settings/Settings'
import Resolution from '../cmp/Resolution'

class PageResolution extends Component {

   constructor(props) {
        super(props);
           this.state = {
                    data: "",
                }
   }

    fetch(id) {
        //---- Fetch resolution information from the backend and store locally
        axios.get(Settings.API_URL_RESOLUTION + "/" + id).then(response => {
        this.setState({data:response.data})})
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        this.fetch(params.id);
    }

    render () {
        console.log(this.state.data)
        return (
            <div className="resolution">
            <Resolution data={this.state.data}/>
            </div>
        )
    }
}

export default PageResolution;