import React, { Component } from 'react'
import { Menu, Container } from 'semantic-ui-react'

class MenuBar extends Component {

    render() {
        return (
        <Container>
        <Menu>
            <Menu.Item name="home"><a href="/main">Home</a></Menu.Item>
            <Menu.Item name="about"><a href="/about">About</a></Menu.Item>
            <Menu.Item name="contact"><a href="/contact">Contact</a></Menu.Item>
        </Menu>
        </Container>
                );

    }
}

export default MenuBar;