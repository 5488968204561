import React, { Component } from 'react'
import { Header,Container, Tab, Segment} from 'semantic-ui-react'
import axios from 'axios'

import Settings from '../settings/Settings'
import SearchBasic from '../cmp/SearchBasic'
import SearchGeneral from '../cmp/SearchGeneral'
import SearchAdvanced from '../cmp/SearchAdvanced'

const panes = [
  { menuItem: 'Basic', render: () => <Tab.Pane><SearchBasic/></Tab.Pane> },
 // { menuItem: 'General', render: () => <Tab.Pane><SearchGeneral/></Tab.Pane> },
 // { menuItem: 'Advanced', render: () => <Tab.Pane><SearchAdvanced/></Tab.Pane> },
]

class PageMain extends Component {

   constructor(props) {
        super(props);
           this.state = {
                    data: "",
                }
   }

    fetch() {

        axios.get(Settings.API_URL_CONTENT_MAIN).then(response => {
        this.setState({data:response.data})})
    }

    componentDidMount() {
        this.fetch();
    }

    render () {
        console.log(this.state.data)
        return (
            <div className="home-content">
                <Container>
                <Segment>
                Write some info about search here
                <Tab panes={panes} onTabChange={this.handleChange} />
                </Segment>
                </Container>
            </div>
        )
    }
}

export default PageMain;