import React, { Component } from 'react'
import { Segment, Image, Container, Table, Input, Dropdown, Button } from 'semantic-ui-react'


class FormSanctions extends Component {

    render() {
        return (
            <div>
            Items regulated
            <Table>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>Item</Table.Cell>
                        <Table.Cell><Dropdown placeholder="item"/></Table.Cell>
                        <Table.Cell>Direction</Table.Cell>
                        <Table.Cell><Dropdown placeholder="direction"/></Table.Cell>
                        <Table.Cell><Button>A</Button></Table.Cell>
                        <Table.Cell><Button>R</Button></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Other prohibitions</Table.Cell>
                        <Table.Cell><Dropdown placeholder="item"/></Table.Cell>
                        <Table.Cell><Button>A</Button></Table.Cell>
                        <Table.Cell><Button>R</Button></Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>Exceptions</Table.Cell>
                        <Table.Cell><Dropdown placeholder="item"/></Table.Cell>
                        <Table.Cell><Button>A</Button></Table.Cell>
                        <Table.Cell><Button>R</Button></Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>


            Target

            <Table>
            <Table.Body>
                <Table.Row>

                </Table.Row>

            </Table.Body>
            </Table>
            </div>

                );

    }
}

export default FormSanctions;