import axios from 'axios'
import React, { Component } from 'react'
import { Input, Table, Label, Form, Dropdown, Button, Container } from 'semantic-ui-react'

import Settings from '../settings/Settings'
import FormBasic from './FormBasic'
import SearchResult from './SearchResult'

class SearchBasic extends Component {


    formatDate(date) {
        if (date === "") {return "";}

        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    constructor(props) {
        super(props)

        this.state = {
            dateMin: "",
            dateMax: "",
            resolutionId: "",
            resolutionTitle: "",
            word: "",
            searchResult: []

        }
    }

    onChangeDate = (e, data) => {
        switch(data.id) {
            case "date-start": this.setState({dateMin: data.value}); break
            case "date-end": this.setState({dateMax: data.value}); break
            case "res-id": this.setState({resolutionId: data.value}); break
            case "res-title": this.setState({resolutionTitle: data.value}); break
        }
    }


    onClickButtonSearch = (e) => {
        let message = new FormData();
        message.append('query-type', 0)
        message.append('date-min', this.formatDate(this.state.dateMin))
        message.append('date-max', this.formatDate(this.state.dateMax))
        message.append('res-id', this.state.resolutionId.toString())
        message.append('title', this.state.resolutionTitle.toString())

        axios.post(Settings.API_URL_SEARCH, message).then(response => {
                console.log(response.data);
                this.setState({searchResult: response.data})
        })
    }


    render() {

        var renderedResult = ""
        if (this.state.searchResult.length != 0) {
            renderedResult = <SearchResult data={this.state.searchResult}/>
        }

        return (
            <div>
            <Form id="search-basic">
            <FormBasic callback={this.onChangeDate}/>
            <Container  textAlign='right'><Button onClick={this.onClickButtonSearch}>Search</Button></Container>
            {renderedResult}
            </Form>
            </div>
                );

    }
}

export default SearchBasic;