import React, { Component } from 'react'
import { Segment, Image, Container, Table } from 'semantic-ui-react'


class Header extends Component {

    render() {
        return (
            <Container>
            <Image className="page-header-img" src={'/images/header2.png'}/>
            </Container>
                );

    }
}

export default Header;