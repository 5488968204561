import React, { Component } from 'react'
import { Container, Icon } from 'semantic-ui-react'

import './css/footer.css'

class Footer extends Component {

    render() {
        return (
            <div>Write some info about search here</div>
                );

    }
}

export default Footer;