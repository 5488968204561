import React, { Component } from 'react'
import { Header,Container, Tab, Segment, Button} from 'semantic-ui-react'
import axios from 'axios'

import FormGeneral from './FormGeneral'
import FormSanctions from './FormSanctions'

import './css/search-adv.css'


const panes = [
  { menuItem: 'General', render: () => <Tab.Pane><FormGeneral/></Tab.Pane> },
  { menuItem: 'Sanctions', render: () => <Tab.Pane><FormSanctions/></Tab.Pane> },
  { menuItem: 'UNPKD', render: () => <Tab.Pane>UNPKD</Tab.Pane> },
]

class SearchAdvanced extends Component {

   constructor(props) {
        super(props);
           this.state = {
                    data: "",
                }
   }

    fetch() {

        //axios.get(Settings.API_URL_CONTENT_MAIN).then(response => {
        //this.setState({data:response.data})})
    }

    componentDidMount() {
        this.fetch();
    }

    render () {
        console.log(this.state.data)
        return (
              <div>
              <Segment className="sa-container">
              <Tab panes={panes} menu={{vertical: true}}  onTabChange={this.handleChange} /></Segment>
              <Container  textAlign='right'><Button>Search</Button></Container>
              </div>
                )
    }
}

export default SearchAdvanced;