import React, { Component } from 'react'
import {Table, Button, Dropdown, Input, Checkbox } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import FormBasic from './FormBasic'


const options = [
  { key: 1, text: 'Europe', value: 1 },
  { key: 2, text: 'Asia', value: 2 },
  { key: 3, text: 'Africa', value: 3 },
]

class FormGeneral extends Component {

  constructor(props) {
        super(props)

        this.state = {
            chapterCellCount: 1,
            regionCellCount: 1,

            dateStart: "",
            dateEnd: ""
        }
    }

    onChangeDate = (e, data) => {
        switch(data.id) {
            case "date-start": this.setState({dateStart: data.value}); break
            case "date-end": this.setState({dateEnd: data.value}); break
        }
    }

    //-----------------------------------------------------------

    regionBuildTable() {
        var html = []
        for (var i = 1; i <= this.state.regionCellCount; i++) {
            html.push(
            <Table.Row>
            <Table.Cell>Continent:</Table.Cell>
            <Table.Cell><Dropdown clearable placeholder="continent" options={options}/></Table.Cell>
            <Table.Cell>Region:</Table.Cell>
            <Table.Cell><Dropdown placeholder="region"/></Table.Cell>
            </Table.Row>
            )
        }

        return (
            <Table>
            <Table.Body>
            {html}
            </Table.Body>
            </Table>
        )

    }

    regionOnClickAdd = (e) => {
        if (this.state.regionCellCount < 5) {
            this.setState({regionCellCount: this.state.regionCellCount + 1})
        }
    }

    regionOnClickRemove = (e) => {
        if (this.state.regionCellCount > 1) {
            this.setState({regionCellCount: this.state.regionCellCount - 1})
        }
    }

    //-----------------------------------------------------------

    chapterBuildTable() {
        var html = []
        for (var i = 1; i <= this.state.chapterCellCount; i++) {
            html.push(
            <Table.Row>
            <Table.Cell>Chapter ID</Table.Cell>
            <Table.Cell><Dropdown clearable placeholder="chapter"/></Table.Cell>
            </Table.Row>
            )
        }

        return (
            <Table>
            <Table.Body>
            {html}
            </Table.Body>
            </Table>
        )
    }

    chapterOnClickAdd = (e) => {
        if (this.state.chapterCellCount < 5) {
            this.setState({chapterCellCount: this.state.chapterCellCount + 1})
        }
    }

    chapterOnClickRemove = (e) => {
        if (this.state.chapterCellCount > 1) {
            this.setState({chapterCellCount: this.state.chapterCellCount - 1})
        }
    }

    render() {
        return (
            <div>

            <FormBasic/>

            Geographical location
            <Table>
            <Table.Body>

                <Table.Row>
                <Table.Cell>
                {this.regionBuildTable()}
                </Table.Cell>
                <Table.Cell>
                <Button onClick={this.regionOnClickAdd}>Add</Button>
                <Button onClick={this.regionOnClickRemove}>Remove</Button>
                </Table.Cell>
                </Table.Row>

            </Table.Body>
            </Table>

            Chapters
            <Table>
            <Table.Body>

                <Table.Row>
                <Table.Cell>
                {this.chapterBuildTable()}
                </Table.Cell>
                <Table.Cell>
                <Button onClick={this.chapterOnClickAdd}>Add</Button>
                <Button onClick={this.chapterOnClickRemove}>Remove</Button>
                </Table.Cell>
                </Table.Row>

            </Table.Body>
            </Table>
            <Checkbox label='ICC referal' />


            </div>
                );

    }
}

export default FormGeneral;