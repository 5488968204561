import React, { Component } from 'react'
import { Segment, Image, Container, Table, Header } from 'semantic-ui-react'

import './css/res.css'

class Resolution extends Component {


    //---- Generate a table that contains references to other resolutions
    renderReferenceResolutions() {
        const tableCellCount = 8
        let output = []
        if (this.props.data.referenceResolutions) {
            let size =  this.props.data.referenceResolutions.length
            for (var i = 0; i < size / tableCellCount; i++) {
                let temp = []
                for (var j = 0; j < tableCellCount; j++) {
                    let index = i * tableCellCount + j
                    let dataShard = this.props.data.referenceResolutions[index]
                    if (!(dataShard === "")) {
                        if (index <= size - 1) {temp.push(<td className="ref-table-cell"><a href={"/resolution/" + dataShard}>S/RES/{dataShard}</a></td>)}
                        else {temp.push(<td className="ref-table-cell"></td>)}
                    }
                }
                output.push(<tr className="ref-table-row">{temp}</tr>)
            }
       }
       return output
    }

    //---- Generate a table that contains references to president statements
    renderReferenceStatements() {
        const tableCellCount = 8
        let output = []
        if (this.props.data.referenceStatements) {
            let size =  this.props.data.referenceStatements.length
            for (var i = 0; i < size / tableCellCount; i++) {
                let temp = []
                for (var j = 0; j < tableCellCount; j++) {
                    let index = i * tableCellCount + j
                    let dataShard = this.props.data.referenceStatements[index]
                    if (index <= size - 1) {temp.push(<td className="ref-table-cell">{dataShard}</td>)}
                    else {temp.push(<td className="ref-table-cell"></td>)}
                }
                output.push(<tr className="ref-table-row">{temp}</tr>)
            }
       }
       return output
    }

    //---- Generate a table that contains references to other documents
    renderReferenceDocuments() {
        const tableCellCount = 5
        let output = []
        if (this.props.data.referenceDocuments) {
            let size =  this.props.data.referenceDocuments.length
            for (var i = 0; i < size / tableCellCount; i++) {
                let temp = []
                for (var j = 0; j < tableCellCount; j++) {
                    let index = i * tableCellCount + j
                    let dataShard = this.props.data.referenceDocuments[index]
                    if (index <= size - 1) {temp.push(<td className="ref-table-cell">{dataShard}</td>)}
                    else {temp.push(<td className="ref-table-cell"></td>)}
                }
                output.push(<tr className="ref-table-row">{temp}</tr>)
            }
       }
       return output
    }

    render() {

        var locations = ""
        if (this.props.data.locations) { locations = this.props.data.locations.toString() }

        var referenceResolutions = this.renderReferenceResolutions()
        var referenceStatements = this.renderReferenceStatements()
        var referenceDocuments = this.renderReferenceDocuments()

        return (
            <Container>
            <Segment>

            <Table>
                <Table.Body>
                <Table.Row>
                <Table.Cell><Header as="h1">Resolution {this.props.data.resolutionNumber}</Header></Table.Cell>
                <Table.Cell textAlign='right'>{this.props.data.resolutionDate}</Table.Cell>
                </Table.Row>

                <Table.Row>
                <Table.Cell colSpan={2}><Header>{this.props.data.resolutionTitle}</Header></Table.Cell>
                </Table.Row>
                </Table.Body>
            </Table>

            <Table>
            <Table.Row>
                <Table.Cell>
                    <table>
                        <tr><td>Locations</td><td>{locations}</td></tr>
                        <tr><td>Threat level</td><td>{this.props.data.threatLevel}</td></tr>
                        <tr><td>Chapters invoked</td><td>{this.props.data.chaptersInvoked}</td></tr>
                        <tr><td>ICC referral</td><td>TODO</td></tr>
                    </table>

                </Table.Cell>
            </Table.Row>

             <Table.Row>
                <Table.Cell>
                    <Header as='h5'>Reference resolutions</Header>
                    <table className="ref-table">{referenceResolutions}</table>
                </Table.Cell>
             </Table.Row>

             <Table.Row>
                <Table.Cell>
                    <Header as='h5'>Reference president statements</Header>
                    <table className="ref-table">{referenceStatements}</table>
                </Table.Cell>
             </Table.Row>

             <Table.Row>
                <Table.Cell>
                    <Header as='h5'>Other documents</Header>
                    <table className="ref-table">{referenceDocuments}</table>
                </Table.Cell>
             </Table.Row>




            </Table>


            </Segment>
            </Container>
                );

    }
}

export default Resolution;